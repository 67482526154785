export const ROLES = {
  // roles with immediate check
  DASHBOARD_ACCESS: "dashboard-access",
  ADMIN: "admin",
  TENANT_SELECT: "tenant_select",
  // new customer roles
  ACCOUNT_ADMIN: "account_admin",
  CONTENT_EDITOR: "content_editor",
  OPERATOR: "operator",
  SMARTDOC_USER: "smartdoc_user",
  // composite roles that can be used to check for page based access
  // e.g. content_editor also has page_qna_editor role but not page_settings
  PAGE: {
    CONTACTS: "page_contacts",
    DIALOGUE_EDITOR: "page_dialogue_editor",
    DIALOGUE_EDITOR_DESIGN: "page_dialogue_editor_design",
    QNA_EDITOR: "page_qna_editor",
    KNOWLEDGE_MANAGEMENT: "page_knowledge_management",
    CONVERSATIONS: "page_conversations",
    SETTINGS: "page_settings",
    STATISTICS: "page_statistics",
    MANAGEMENT_STATISTICS: "page_management_statistics",
    CAMPAIGNS: "page_campaigns",
    MAPPING_EDITOR: "page_mapping_editor",
    TICKETS: "page_tickets",
    SMARTDOC: "page_smartdoc",
    SMARTDOC_UPLOAD: "page_smartdoc_upload",
    SMARTDOC_DELETE: "page_smartdoc_delete",
    SMARTDOC_DOCUMENTS: "page_smartdoc_documents",
    SMARTDOC_AITABLE: "page_smartdoc_aitable",
    SMARTDOC_MULTIDOC: "page_smartdoc_multidoc",
    SMARTDOC_DOCUMENT_VIEW: "page_smartdoc_document_view",
  },
  NODE_CATEGORY: {
    QUESTION_NODES: "node_category_questions",
    TEMPLATE_NODES: "node_category_templates",
    INTEGRATION_NODES: "node_category_integrations",
  },
};
